
import { defineComponent } from 'vue'
import WatFocus from '~/components/WatFocus/WatFocus.vue'
import store from '@/store'
import { SAVE_OBSERVATOR_NAME } from '~/store/watfocus.store'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import Button from '@/components/Button.vue'

import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    name: 'WatFocusExecution',
    components:{
        WatFocus,
        SuiteInput,
        Button
    },setup() {
    const router = useRouter()
    const route = useRoute()

    return {
        router,
        route
    }
},
    props:{
        isObservator:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            showFocus:false,
            showForm: false,
            name:''
        }
    },
    methods:{
        async loginObservator(){
            if(this.name.length < 3) return
            await store.dispatch(SAVE_OBSERVATOR_NAME,{id:this.route.params.id, slotHash:this.route.params.slotHash,data:{name:this.name}})
            this.showForm = false
            this.showFocus = true
        },
        exit(){
            if(this.isObservator) this.router.go(-1)
            else window.close()
        }
    },
    mounted(){
        if(this.isObservator) this.showForm = true        
        else this.showFocus = true
    }
})
