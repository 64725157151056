var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import VideochatComments from './VideochatComments.vue';
import AccessPermissionsModal from './AccessPermissionsModal.vue';
import Chat from './Chat.vue';
import ActionsBar from './ActionsBar.vue';
import AccessPermissions from './AccessPermissions.vue';
import store from '@/store';
import Pusher from 'pusher-js';
import { mapGetters } from 'vuex';
import { GRANT_TESTER_ACCESS, FETCH_TESTER_JOIN_SLOT, FETCH_FOCUS_DATA_OBS, FETCH_FOCUS_DATA_MODERATOR, FETCH_WAT_FOCUS_DATA, FETCH_ONLINE_USERS, FETCH_WAT_FOCUS_USERS, WAT_FOCUS_PUT_MESSAGE_OBSERVATOR, WAT_FOCUS_PUT_MESSAGE, WAT_FOCUS_PUT_MESSAGE_PUBLIC, FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS, FETCH_WAT_FOCUS_CHAT_MESSAGES, ADD_FOCUS_CHAT_MESSAGE, ADD_FOCUS_OBSERVER_CHAT_MESSAGE, FETCH_WAT_FOCUS_FILE, PUT_WAT_FOCUS_COMMENT } from '../../store/watfocus.store';
import { initSession, connectSession, getSession, getPublisher, createSignal, muteCurrentUser, canShareScreen, applyBlurEffect } from '../../utils/videochat-restyling.js';
export default {
    name: 'WatFocus',
    components: {
        Chat: Chat,
        ActionsBar: ActionsBar,
        AccessPermissions: AccessPermissions,
        VideochatComments: VideochatComments,
        AccessPermissionsModal: AccessPermissionsModal
    },
    props: {
        badgePermissions: {
            type: Number,
            default: 0
        },
        activityMembers: Object,
        isClient: {
            type: Boolean,
            default: false
        },
        isUser: {
            type: Boolean,
            default: false
        },
        isObservator: {
            type: Boolean,
            default: false
        },
        isModerator: {
            type: Boolean,
            default: false
        },
        isPublic: {
            type: Boolean,
            default: false
        },
        nickname: {
            type: String,
            default: ""
        },
        visitorToken: {
            type: String,
            default: ""
        },
        testerAuthToken: {
            type: String,
            default: ""
        }
    },
    computed: __assign({}, mapGetters([
        'focusChatMessagesObs',
        'focusChatMessages'
    ])),
    data: function () {
        return {
            selectedTab: 0,
            isFullSize: false,
            isMuted: false,
            isMutedByModerator: false,
            isSharingScreen: false,
            isSharingScreenExternal: false,
            screenPublisher: null,
            badgeChatActivity: 0,
            recordingStartedDateInMs: 0,
            currentTimeFromStart: 0,
            currentSecondsFromStart: 0,
            showPermissionsModal: false,
            deniedUsers: [],
            permissionUserName: '',
            testerName: '',
            isBlurEffect: false,
            watFocusTesters: [],
            accessUsers: [],
            chatPusher: null,
            chatConnected: false,
            badgeChatObserver: 0,
            watFocusData: {
                testerNum: 0,
                slot: {
                    dateFrom: ''
                }
            },
            hasAttemptedAccess: false
        };
    },
    emits: ['hide-header'],
    watch: {
        badgeChatObserver: function (val) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (this.selectedTab == 1 && this.badgeChatObserver > 0) {
                        this.badgeChatObserver = 0;
                    }
                    return [2 /*return*/];
                });
            });
        },
        badgePermissions: {
            handler: function (val) {
                return __awaiter(this, void 0, void 0, function () {
                    var index, user;
                    return __generator(this, function (_a) {
                        if (this.showPermissionsModal)
                            return [2 /*return*/];
                        if (this.isObservator)
                            return [2 /*return*/];
                        for (index in this.accessUsers) {
                            user = this.accessUsers[index];
                            if (!user.accessGranted && user.online && !this.deniedUsers.includes(index)) {
                                this.permissionUserName = index;
                                this.testerName = user.testerName;
                                this.showPermissionsModal = true;
                                return [2 /*return*/];
                            }
                        }
                        return [2 /*return*/];
                    });
                });
            },
            immediate: true
        }
    },
    methods: {
        exit: function () {
            this.destroySession();
            if (this.isUser && !this.isPublic)
                this.$router.push({ name: 'tests.index' });
            this.$emit('exit');
            if (!this.isUser || (this.isUser && !this.isPublic))
                Swal.fire('', '¡Gracias por participar en la videollamada!', 'success');
        },
        destroySession: function () {
            if (getSession() != null) {
                getSession().off();
                getSession().disconnect();
            }
            if (getPublisher() != null)
                getPublisher().destroy();
            if (this.screenPublisher != null && this.isSharingScreen)
                this.screenPublisher.destroy();
        },
        changeTab: function (tabIndex) {
            if (this.selectedTab == tabIndex)
                return this.selectedTab = -1;
            if (tabIndex == 1)
                this.$emit('observer-chat-opened');
            if (tabIndex == 0)
                this.badgeChatActivity = 0;
            if (tabIndex == 3)
                this.openCommentsModal();
            this.selectedTab = tabIndex;
        },
        fullSize: function () {
            this.isFullSize = !this.isFullSize;
            store.commit('setHideHeader', this.isFullSize);
        },
        detectArchivingStart: function (data) {
            var vm = this;
            getSession().on("archiveStarted", function (event) {
                store.dispatch(FETCH_WAT_FOCUS_FILE, { archiveId: event.id })
                    .then(function (_a) {
                    var data = _a.data;
                    vm.recordingStartedDateInMs = data.createdAt;
                });
            });
        },
        detectStreamDestroyed: function (data) {
            var vm = this;
            getSession().on("streamDestroyed", function (event) {
                if (vm.isSharingScreenExternal && event.stream.videoType == "screen") {
                    vm.isSharingScreenExternal = false;
                }
            });
        },
        detectNewStream: function (data) {
            var vm = this;
            getSession().on('streamCreated', function (event) {
                var isScreenStream = event.stream.videoType === 'screen';
                var DOMElement = isScreenStream ? 'Videochat--Screen' : 'Videochat--StreamsFakeContainer';
                var isModerator = event.stream.connection.data.split("=")[1] == "Moderador" ? true : false;
                var insertMode = isScreenStream ? 'append' : isModerator ? 'before' : 'after';
                var subscriberProperties = {
                    insertMode: insertMode,
                    width: '100%',
                    height: '100%',
                    style: { buttonDisplayMode: 'off', nameDisplayMode: 'on', audioBlockedDisplayMode: 'on' }
                };
                var subscriber = getSession().subscribe(event.stream, DOMElement, subscriberProperties, function (error) {
                    if (event.stream.videoType == 'screen')
                        vm.isSharingScreenExternal = true;
                    if (vm.isModerator)
                        vm.generateUserActions(subscriber, event.stream.videoType == 'screen');
                });
            });
        },
        detectSignals: function (data) {
            var vm = this;
            getSession().on("signal", function (event) {
                var eventType = event.data.type;
                if (eventType == 'MUTE')
                    vm.executeSignalMute(event, true);
                else if (eventType == 'UNMUTE')
                    vm.executeSignalMute(event, false);
                else if (eventType == 'REMOVE_USER')
                    vm.executeSignalRemoveUser(event);
                else if (eventType == 'CLOSE_USER_SCREEN')
                    vm.executeSignalCloseScreen(event);
            });
        },
        executeSignalMute: function (event, mute) {
            if (mute)
                $('#' + event.data.userId).append("<div class='UserMutedIcon'><i class='fas fa-volume-mute'></i></div>");
            else
                $('#' + event.data.userId + ' .UserMutedIcon').remove();
            if (getPublisher().streamId == event.data.id && !this.isModerator) {
                this.isMutedByModerator = mute;
                muteCurrentUser(!mute);
            }
        },
        executeSignalRemoveUser: function (event) {
            if (getPublisher().streamId == event.data.id && !this.isModerator) {
                Swal.fire({
                    text: "Motivo de la expulsión: " + event.data.message,
                    icon: "error",
                    customClass: { popup: "swal-customError" }
                });
                this.exit();
            }
        },
        executeSignalCloseScreen: function (event) {
            if (!this.isModerator)
                this.stopScreenSharing();
        },
        generateUserActions: function (subscriber, isScreenStream) {
            $("#" + subscriber.id).append("<div class='UserMenuActions'><i class='fas fa-ellipsis-v'></i></div>");
            $("#" + subscriber.id).append("<div class='UserMenuActions--List'><p class='UserMenuActions--Exit'><i class='fas fa-user-times'></i>" + (isScreenStream ? "Cerrar pantalla" : "Expulsar") + "</p><p class='UserMenuActions--Mute'><i class='fas fa-microphone-alt UserMenuActions--notMute'></i><i class='fas fa-microphone-alt-slash UserMenuActions--muted'></i>Silenciar</p></div>");
            this.generateClickEventHandlers(subscriber, isScreenStream);
        },
        generateClickEventHandlers: function (subscriber, isScreenStream) {
            var vm = this;
            $(window).click(function () {
                $("#" + subscriber.id + " .UserMenuActions--List").hide();
            });
            $("#" + subscriber.id + " .UserMenuActions").on("click", function (event) {
                event.stopPropagation();
                var element = $("#" + subscriber.id + " .UserMenuActions--List");
                if (element.is(":visible"))
                    element.hide();
                else
                    element.show().css('display', 'flex');
            });
            $("#" + subscriber.id + " .UserMenuActions--Mute").on("click", function (event) {
                event.stopPropagation();
                var element = $("#" + subscriber.id + " .UserMenuActions--Mute");
                if (element.hasClass("userMute")) {
                    createSignal(subscriber.id, subscriber.streamId, "external", "UNMUTE", null);
                    element.removeClass('userMute');
                }
                else {
                    createSignal(subscriber.id, subscriber.streamId, "external", "MUTE", null);
                    element.addClass('userMute');
                }
            });
            $("#" + subscriber.id + " .UserMenuActions--Exit").on("click", function (event) {
                event.stopPropagation();
                if (isScreenStream) {
                    Swal.fire({
                        text: vm.$t('user_will_stop_sharing_screen'),
                        icon: 'warning',
                        cancelButtonText: vm.$t('action_cancel'),
                        confirmButtonText: vm.$t('action_continue'),
                        showCancelButton: true,
                        customClass: {
                            popup: 'swal-customWarning'
                        }
                    }).then(function (result) {
                        if (result.isConfirmed) {
                            createSignal(subscriber.id, subscriber.streamId, "external", "CLOSE_USER_SCREEN");
                        }
                    });
                }
                else {
                    Swal.fire({
                        title: vm.$t('msg_videochat_out_message'),
                        text: vm.$t('msg_videochat_out_reason'),
                        input: 'text',
                        showCancelButton: true,
                        cancelButtonText: vm.$t('action_cancel'),
                        confirmButtonText: vm.$t('action_out')
                    }).then(function (result) {
                        if (result.isConfirmed && result.value != null) {
                            vm.$emit('user-removed', subscriber.stream.name);
                            vm.deniedUsers.push(subscriber.stream.name);
                            createSignal(subscriber.id, subscriber.streamId, "external", "REMOVE_USER", result.value);
                        }
                    });
                }
            });
        },
        hasPermissionsToShare: function () {
            return !this.isObservator && (this.isUser && !this.isSharingScreenExternal) || this.isModerator;
        },
        showScreenPermissionError: function () {
            var vm = this;
            Swal.fire({
                text: vm.$t('check_your_permissions'),
                icon: "error",
                customClass: { popup: "swal-customError" }
            });
        },
        publishScreenShare: function () {
            var vm = this;
            var participantName = this.nickname;
            var publisherOptions = {
                name: this.isClient ? "Moderador" : participantName,
                insertMode: 'append',
                width: '100%',
                height: '100%',
                videoSource: 'screen',
                nameDisplayMode: "on"
            };
            this.screenPublisher = OT.initPublisher('Videochat--Screen', publisherOptions, function (error) {
                if (error) {
                    vm.isSharingScreen = false;
                    if (error.originalMessage != 'Permission denied')
                        vm.showScreenPermissionError();
                }
                else {
                    getSession().publish(vm.screenPublisher, function (error) {
                        if (error) {
                            vm.isSharingScreen = false;
                            vm.showScreenPermissionError();
                            if (error.name != null && error.name == 'OT_UNEXPECTED_SERVER_RESPONSE') {
                                console.log("SERVER ERROR: OpenTok unexpected error");
                            }
                        }
                        vm.isSharingScreen = true;
                    });
                }
            });
            this.screenPublisher.on({
                streamDestroyed: function (event) {
                    vm.isSharingScreen = false;
                }
            });
        },
        shareScreen: function () {
            if (!canShareScreen()) {
                var vm = this;
                Swal.fire(vm.$t('browser_can_not_share_screen'));
                return;
            }
            if (this.isSharingScreen) {
                this.stopScreenSharing();
                return;
            }
            this.publishScreenShare();
        },
        stopScreenSharing: function () {
            this.isSharingScreen = false;
            this.screenPublisher.destroy();
        },
        setStreamsGridSize: function () {
            var usersNum = this.watFocusData.testerNum;
            if (this.isObservator)
                usersNum++;
            if (usersNum == 2)
                $('.Videochat--Streams').addClass("Stream--min");
            if (usersNum >= 3 && usersNum <= 4)
                $('.Videochat--Streams').addClass("Stream--few");
            if (usersNum >= 5 && usersNum <= 6)
                $('.Videochat--Streams').addClass("Stream--medium");
            if (usersNum >= 7 && usersNum <= 8)
                $('.Videochat--Streams').addClass("Stream--almostCompleted");
            if (usersNum >= 9 && usersNum <= 12)
                $('.Videochat--Streams').addClass("Stream--full");
            if (usersNum >= 13)
                $('.Videochat--Streams').addClass("Stream--infinite");
        },
        mute: function () {
            if (this.isMutedByModerator)
                return;
            this.isMuted = !this.isMuted;
            muteCurrentUser(!this.isMuted);
        },
        newMessageChat: function (message) {
            if ((this.isUser && message.isModerator) || (this.isClient && !message.isModerator))
                this.badgeChatActivity++;
        },
        closeTabsOnmobileView: function () {
            if (window.innerWidth <= 768)
                this.selectedTab = -1;
        },
        openCommentsModal: function () {
            return __awaiter(this, void 0, void 0, function () {
                var currentDate, start, hours, minutes, seconds;
                return __generator(this, function (_a) {
                    currentDate = new Date();
                    start = new Date(this.recordingStartedDateInMs);
                    hours = parseInt(Math.abs(start - currentDate) / (1000 * 60 * 60) % 24);
                    minutes = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000 * 60) % 60);
                    seconds = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000) % 60);
                    this.currentTimeFromStart = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
                    this.currentSecondsFromStart = parseInt(Math.abs((currentDate - start) / 1000));
                    return [2 /*return*/];
                });
            });
        },
        sendVideoFileComment: function (comment) {
            return __awaiter(this, void 0, void 0, function () {
                var videoCommentData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            videoCommentData = {
                                secondsFromStart: this.currentSecondsFromStart,
                                comment: comment
                            };
                            return [4 /*yield*/, store.dispatch(PUT_WAT_FOCUS_COMMENT, { data: videoCommentData })
                                    .then(function (data) {
                                    var vm = _this;
                                    Swal.fire('', vm.$t('annotation_saved'), 'success');
                                    _this.changeTab(-1);
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        grantAccessFromModal: function (event) {
            var user = this.accessUsers[event.userName];
            user.accessGranted = event.accessGranted;
            if (event.accessGranted)
                this.grantAccessToTester(user);
            else
                this.deniedUsers.push(user.nickName);
            this.showPermissionsModal = false;
        },
        applyBlurEffect: function () {
            this.isBlurEffect = !this.isBlurEffect;
            applyBlurEffect(this.isBlurEffect);
        },
        /* NEW WATFOCUS FUNCTIONS */
        joinToWatFocus: function (sessionData) {
            return __awaiter(this, void 0, void 0, function () {
                var participantName;
                return __generator(this, function (_a) {
                    if (sessionData.sessionId != undefined && sessionData.sessionId != null) {
                        participantName = this.nickname;
                        initSession(sessionData.sessionId);
                        connectSession(sessionData.token, this.isObservator, this.isClient, participantName);
                        this.detectNewStream(sessionData);
                        this.detectStreamDestroyed(sessionData);
                        this.detectSignals(sessionData);
                        if (this.isModerator)
                            this.detectArchivingStart(sessionData);
                        this.setStreamsGridSize();
                    }
                    return [2 /*return*/];
                });
            });
        },
        joinTester: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(FETCH_TESTER_JOIN_SLOT, { id: this.$route.params.id, slotHash: this.$route.params.slotHash, isPublic: this.isPublic, visitorToken: this.visitorToken })
                                .then(function (data) {
                                if (data.accessGranted) {
                                    Swal.close();
                                    _this.joinToWatFocus(data);
                                }
                                else if (!_this.hasAttemptedAccess) {
                                    _this.hasAttemptedAccess = true;
                                    Swal.fire({
                                        text: _this.$t('msg_videochat_waiting_permission'),
                                        icon: "info",
                                        customClass: { popup: "swal-customInfo" },
                                        allowOutsideClick: false,
                                        showCancelButton: false,
                                        showConfirmButton: false
                                    });
                                    setTimeout(_this.joinTester, 5000);
                                }
                                else {
                                    setTimeout(_this.joinTester, 5000);
                                }
                            })
                                .catch(function (response) {
                                if (!_this.hasAttemptedAccess) {
                                    _this.hasAttemptedAccess = true;
                                    Swal.fire({
                                        text: _this.$t('msg_videochat_waiting_permission'),
                                        icon: "info",
                                        customClass: { popup: "swal-customInfo" },
                                        allowOutsideClick: false,
                                        showCancelButton: false,
                                        showConfirmButton: false
                                    });
                                }
                                setTimeout(_this.joinTester, 5000);
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        joinModerator: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(FETCH_FOCUS_DATA_MODERATOR, { id: this.$route.params.id, slotHash: this.$route.params.slotHash })
                                .then(function (data) {
                                _this.joinToWatFocus(data);
                            })
                                .catch(function (response) {
                                Swal.fire("", "Sesión no disponible hasta " + _this.watFocusData.slot.dateFrom, "error");
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        joinObservator: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(FETCH_FOCUS_DATA_OBS, { id: this.$route.params.id, slotHash: this.$route.params.slotHash })
                                .then(function (data) {
                                _this.joinToWatFocus(data);
                            })
                                .catch(function (response) {
                                Swal.fire({
                                    text: _this.$t('msg_videochat_waiting_permission'),
                                    icon: "info",
                                    customClass: { popup: "swal-customInfo" },
                                    allowOutsideClick: false,
                                    showCancelButton: false,
                                    showConfirmButton: false
                                });
                                setTimeout(_this.joinObservator, 5000);
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchWatFocus: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(FETCH_WAT_FOCUS_DATA, { id: this.$route.params.id, slotHash: this.$route.params.slotHash })
                                .then(function (data) {
                                _this.watFocusData = data;
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchOnlineUsers: function () {
            var _this = this;
            store.dispatch(FETCH_ONLINE_USERS, { id: this.$route.params.id, slotHash: this.$route.params.slotHash })
                .then(function (data) {
                _this.onlineUsers = data.onlineUsers;
                _this.mediaTestResults = data.testResults.testResults;
                var _loop_1 = function (id) {
                    var videochatUser = _.filter(_this.watFocusTesters, function (m) { return m.identifier == id; })[0];
                    if (videochatUser != undefined) {
                        if (_this.mediaTestResults[videochatUser.nickname] != undefined) {
                            _this.mediaTestResults[videochatUser.nickname].online = _this.onlineUsers.testersOnline[id];
                            _this.mediaTestResults[videochatUser.nickname].identifier = id;
                        }
                    }
                };
                for (var id in _this.onlineUsers.testersOnline) {
                    _loop_1(id);
                }
                _this.accessUsers = _this.mediaTestResults;
                setTimeout(_this.fetchOnlineUsers, 3000);
            });
        },
        fetchWatFocusTesters: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(FETCH_WAT_FOCUS_USERS, { id: this.$route.params.id, slotHash: this.$route.params.slotHash })
                                .then(function (data) {
                                _this.watFocusTesters = data;
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        grantAccessToTester: function (tester) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.dispatch(GRANT_TESTER_ACCESS, { id: this.$route.params.id, slotHash: this.$route.params.slotHash, testerId: tester.identifier, grantAccess: { grantAccess: tester.accessGranted } })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        subscribeChat: function () {
            if (this.chatConnected)
                return;
            this.chatConnected = true;
            Pusher.logToConsole = false;
            this.chatPusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: 'eu',
                forceTLS: true,
            });
            this.chatPusher.subscribe('chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash);
            this.chatPusher.subscribe('chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash + '-observer');
            this.chatPusher.bind('chat-message', this.handleReceivedMessageVideochat);
        },
        handleReceivedMessageVideochat: function (message) {
            if (message.chatName == 'chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash + '-observer') {
                message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
                message.creationDate += '.307+0000';
                store.commit(ADD_FOCUS_OBSERVER_CHAT_MESSAGE, message);
                if (!message.isModerator && this.isModerator && this.selectedTab == 0) {
                    this.badgeChatObserver++;
                }
            }
            else {
                this.newMessageChat(message);
                message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
                message.creationDate += '.307+0000';
                store.commit(ADD_FOCUS_CHAT_MESSAGE, message);
            }
        },
        sendMessage: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            event.isModerator = this.isModerator;
                            data = {
                                id: this.$route.params.id,
                                slotHash: this.$route.params.slotHash,
                                isModerator: this.isModerator,
                                data: event
                            };
                            if (!(this.selectedTab == 0)) return [3 /*break*/, 5];
                            if (!this.isPublic) return [3 /*break*/, 2];
                            return [4 /*yield*/, store.dispatch(WAT_FOCUS_PUT_MESSAGE_PUBLIC, data)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, store.dispatch(WAT_FOCUS_PUT_MESSAGE, data)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [3 /*break*/, 7];
                        case 5: return [4 /*yield*/, store.dispatch(WAT_FOCUS_PUT_MESSAGE_OBSERVATOR, data)];
                        case 6:
                            _a.sent();
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        fetchChatMessages: function () {
            store.dispatch(FETCH_WAT_FOCUS_CHAT_MESSAGES, { id: this.$route.params.id, slotHash: this.$route.params.slotHash });
        },
        fetchChatMessagesObs: function () {
            store.dispatch(FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS, { id: this.$route.params.id, slotHash: this.$route.params.slotHash });
        }
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fullSize();
                        if (this.isClient)
                            this.fetchChatMessagesObs();
                        this.fetchChatMessages();
                        this.subscribeChat();
                        if (!this.isModerator) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchWatFocusTesters()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.isModerator)
                            this.fetchOnlineUsers();
                        return [4 /*yield*/, this.fetchWatFocus()];
                    case 3:
                        _a.sent();
                        this.closeTabsOnmobileView();
                        if (this.isUser)
                            this.joinTester();
                        else if (this.isObservator)
                            this.joinObservator();
                        else if (this.isModerator)
                            this.joinModerator();
                        return [2 /*return*/];
                }
            });
        });
    }
};
